var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "180px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "应收账款信息" } },
                [
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联基础合同",
                                prop: "contracNo"
                              }
                            },
                            [
                              _c("p", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { href: "javascript:" },
                                    on: { click: _vm.clickContract }
                                  },
                                  [_vm._v("选择基础合同")]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "关联基础合同" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.appForm.contracNo
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "买方企业名称" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.appForm.buyFirmName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "卖方企业名称" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.appForm.sellFirmName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关联发票", prop: "invoiceIds" }
                            },
                            [
                              _c("p", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { href: "javascript:" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickInvoice("edit")
                                      }
                                    }
                                  },
                                  [_vm._v("选择发票")]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "关联发票" } }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickInvoice("view")
                                    }
                                  }
                                },
                                [_vm._v("查看发票")]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "amount", label: "应收金额(元)" } },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.amount,
                                format: function(val) {
                                  return _vm.utils.moneyFormat(Number(val), 2)
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入应收金额"
                                },
                                model: {
                                  value: _vm.appForm.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "amount", $$v)
                                  },
                                  expression: "appForm.amount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收期限", prop: "paymentTerm" } },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.paymentTerm,
                                format: function(val) {
                                  return _vm.utils.dateFormat(val)
                                }
                              }
                            },
                            [
                              _c("ics-search-date", {
                                attrs: {
                                  date: _vm.appForm.paymentTerm,
                                  type: "date"
                                },
                                on: {
                                  "update:date": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "paymentTerm",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col"),
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单信息" } },
                            [
                              _c("ics-upload-inner", {
                                attrs: { "form-info": _vm.appForm.order },
                                on: {
                                  "update:formInfo": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "order",
                                      $event
                                    )
                                  },
                                  "update:form-info": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "order",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit() && _vm.appForm.order
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "订单信息" } }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewFile(_vm.appForm.order)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appForm.order.name))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-col"),
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "送货单" } },
                            [
                              _c("ics-upload-inner", {
                                attrs: {
                                  "form-info": _vm.appForm.deliveryBill
                                },
                                on: {
                                  "update:formInfo": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "deliveryBill",
                                      $event
                                    )
                                  },
                                  "update:form-info": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "deliveryBill",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit() && _vm.appForm.deliveryBill
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "送货单" } }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewFile(
                                        _vm.appForm.deliveryBill
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appForm.deliveryBill.name))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-col"),
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货单" } },
                            [
                              _c("ics-upload-inner", {
                                attrs: {
                                  "form-info": _vm.appForm.receivingNote
                                },
                                on: {
                                  "update:formInfo": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "receivingNote",
                                      $event
                                    )
                                  },
                                  "update:form-info": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "receivingNote",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit() && _vm.appForm.receivingNote
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "收货单" } }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewFile(
                                        _vm.appForm.receivingNote
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appForm.receivingNote.name))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-col"),
                  _vm.viewEdit()
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "月对账单" } },
                            [
                              _c("ics-upload-inner", {
                                attrs: {
                                  "form-info": _vm.appForm.monthlyStatement
                                },
                                on: {
                                  "update:formInfo": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "monthlyStatement",
                                      $event
                                    )
                                  },
                                  "update:form-info": function($event) {
                                    return _vm.$set(
                                      _vm.appForm,
                                      "monthlyStatement",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.viewEdit() && _vm.appForm.monthlyStatement
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "月对账单" } }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewFile(
                                        _vm.appForm.monthlyStatement
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.appForm.monthlyStatement.name)
                                  )
                                ]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.viewEdit()
                    ? _c("ics-button-inner", {
                        attrs: {
                          loading: _vm.loading.submit,
                          "submit-title": "提交"
                        },
                        on: { submit: _vm.submitForms }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ics-contract-inner", {
        attrs: {
          dialog: _vm.dialog.contract,
          "contract-info": _vm.contractInfo
        },
        on: { confirm: _vm.confirmContract }
      }),
      _c("ics-invoice-inner", {
        attrs: {
          dialog: _vm.dialog.invoice,
          "contrac-id": _vm.contractId,
          "view-selection": _vm.viewSelection,
          "invoices-info": _vm.getInvoicesList,
          "invoices-info-list": _vm.getInvoicesList
        },
        on: { confirm: _vm.confirmInvoices }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }