<template>
  <ics-dialog-inner width="60%" :visible.sync="dialog.visible" :title="dialog.title" submit-title="确认" cancel-title="返回" class="form-dialog" :show-submit="viewSelection" @submit="confirmInvoices">
    <div class="partition-table">
      <el-table ref="multipleTable" :data="invoicesList" border style="width: 100%" class="table-input" max-height="250" @selection-change="handleSelectionChange">
        <el-table-column v-if="viewSelection" type="selection" :selectable="setSelection" align="left" min-width="50" />
        <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
        <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
        <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
      </el-table>
    </div>
  </ics-dialog-inner>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
export default {
  name: 'IcsInvoiceInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    dialog: {
      type: Object,
      default () {
        return {}
      }
    },
    viewSelection: {
      type: Boolean,
      default: false
    },
    invoicesInfo: {
      type: Array,
      default () {
        return []
      }
    },
    contracId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      multipleSelection: undefined,
      invoicesList: []
    }
  },
  watch: {
    contracId: {
      handler (val) {
        if (val) {
          this.getInvoicesList(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    getInvoicesList (val) {
      this.api.assets.contract.detailsForReceivablePayable(val).then(result => {
        const data = result.data.data
        this.invoicesList = data.invoicesList
      }).finally(() => {
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 跳页回显已选择的
    setSelection (row, index) {
      const isCheck = this._.find(this.invoicesInfo, (o) => {
        return o.id === row.id
      })
      return !isCheck
    },
    confirmInvoices () {
      this.dialog.visible = false
      this.$emit('confirm', this.multipleSelection)
    }
  }
}
</script>

<style scoped>

</style>
