<!-- table-inner -->
<template>
  <el-col :span="24">
    <el-upload
      v-model="formInfo.fileList"
      class="upload-demo"
      :action="fileUploadUrl"
      :limit="1"
      :on-preview="previewFile"
      :before-upload="beforeUploadFile"
      :on-success="onSuccessFile"
      :on-error="onErrorFile"
      :on-change="onChange"
      :on-remove="onChange"
      :on-exceed="onExceed"
    >
      <el-button :loading="loading.submit" size="small" type="primary">
        点击上传
      </el-button>
    </el-upload>
  </el-col>
</template>

<script>

export default {
  name: 'IcsUploadInner',
  props: {
    formInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    // 查看状态 默认 false
    viewMode: {
      type: Boolean,
      default: false
    },
    // 所属模块
    moduleName: {
      type: [String, Number],
      default: ''
    },
    // 业务ID
    businessId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/channel/b/v1/file/upload?token=' + this.$store.state.token,
      limit: 1,
      loading: {
        submit: false
      }
    }
  },
  methods: {
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    beforeUploadFile (file) {
      const isLt = file.size / 1024 < 100 * 1000
      if (!isLt) {
        this.$message.error('上传文件最大为100M')
        this.loading.submit = false
        return isLt
      }
    },
    onSuccessFile (res, file) {
      if (res.success) {
        this.$message.success(res.message || '上传成功')
      } else {
        this.$message.error(res.message || '上传失败')
        this.formInfo.fileList = []
      }
      this.loading.submit = false
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    onErrorFile (err, file, fileList) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    }
  }
}
</script>

<style scoped>

</style>
