<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="应收账款信息">
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="关联基础合同" prop="contracNo">
              <p>
                <a href="javascript:" class="text-btn" @click="clickContract">选择基础合同</a>
              </p>
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="关联基础合同">
              <p>{{ utils.isEffectiveCommon(appForm.contracNo) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="买方企业名称">
              <p>{{ utils.isEffectiveCommon(appForm.buyFirmName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="卖方企业名称">
              <p>{{ utils.isEffectiveCommon(appForm.sellFirmName) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="关联发票" prop="invoiceIds">
              <p>
                <a href="javascript:" class="text-btn" @click="clickInvoice('edit')">选择发票</a>
              </p>
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="关联发票">
              <p>
                <a href="javascript:" class="text-btn" @click="clickInvoice('view')">查看发票</a>
              </p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item prop="amount" label="应收金额(元)">
              <ics-item-inner :prop="appForm.amount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                <el-input v-model="appForm.amount" type="number" placeholder="请输入应收金额" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="应收期限" prop="paymentTerm">
              <ics-item-inner :prop="appForm.paymentTerm" :format="(val)=>utils.dateFormat(val)">
                <ics-search-date :date.sync="appForm.paymentTerm" type="date" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="订单信息">
              <ics-upload-inner :form-info.sync="appForm.order" />
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit() && appForm.order" :span="12">
            <el-form-item label="订单信息">
              <p><a href="javascript:" class="text-btn" @click="previewFile(appForm.order)">{{ appForm.order.name }}</a></p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="送货单">
              <ics-upload-inner :form-info.sync="appForm.deliveryBill" />
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit() && appForm.deliveryBill" :span="12">
            <el-form-item label="送货单">
              <p><a href="javascript:" class="text-btn" @click="previewFile(appForm.deliveryBill)">{{ appForm.deliveryBill.name }}</a></p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="收货单">
              <ics-upload-inner :form-info.sync="appForm.receivingNote" />
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit() && appForm.receivingNote" :span="12">
            <el-form-item label="收货单">
              <p><a href="javascript:" class="text-btn" @click="previewFile(appForm.receivingNote)">{{ appForm.receivingNote.name }}</a></p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item label="月对账单">
              <ics-upload-inner :form-info.sync="appForm.monthlyStatement" />
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit() && appForm.monthlyStatement" :span="12">
            <el-form-item label="月对账单">
              <p><a href="javascript:" class="text-btn" @click="previewFile(appForm.monthlyStatement)">{{ appForm.monthlyStatement.name }}</a></p>
            </el-form-item>
          </el-col>
          <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
    <ics-contract-inner :dialog="dialog.contract" :contract-info="contractInfo" @confirm="confirmContract" />
    <ics-invoice-inner :dialog="dialog.invoice" :contrac-id="contractId" :view-selection="viewSelection" :invoices-info="getInvoicesList" :invoices-info-list="getInvoicesList" @confirm="confirmInvoices" />
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsContractInner from './components/contract-inner'
import IcsInvoiceInner from './components/invoice-inner'
import IcsSearchDate from '@/components/search-date'
import IcsUploadInner from '@/components/upload-inner'
export default {
  components: { IcsContractInner, IcsInvoiceInner, IcsUploadInner, IcsSearchDate },
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      appForm: {
        contracNo: '',
        buyFirmId: '',
        buyFirmName: '',
        sellFirmId: '',
        sellFirmName: '',
        amount: '',
        invoiceIds: '',
        order: {},
        deliveryBill: {},
        receivingNote: {},
        monthlyStatement: {}
      },
      contractInfo: {},
      contractId: '',
      viewSelection: false,
      getInvoicesList: [],
      dialog: {
        contract: {
          visible: false,
          title: '选择基础合同'
        },
        invoice: {
          visible: false,
          title: '选择发票'
        }
      },
      rules: {
        contracNo: this.changeRules({ name: '关联基础合同', required: true }),
        invoiceIds: this.changeRules({ name: '关联发票', required: true }),
        amount: this.changeRules({ name: '应收金额', required: true }),
        paymentTerm: [
          this.rulesToolkit.rules.required({ name: '付款期限', trigger: 'change' })
        ],
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.api.assets.receivable.receivableDetail(this.id).then(result => {
        const data = result.data.data || {}
        data.filesList.forEach(item => {
          if (item.suffix === 'order') {
            data.order = item
          }
          if (item.suffix === 'delivery_bill') {
            data.deliveryBill = item
          }
          if (item.suffix === 'receiving_note') {
            data.receivingNote = item
          }
          if (item.suffix === 'monthly_statement') {
            data.monthlyStatement = item
          }
        })
        this.appForm = data
        console.log(this.appForm)
      }).finally(() => {
      })
    },
    clickContract () {
      this.dialog.contract.visible = true
    },
    clickInvoice (type) {
      if (type === 'edit') {
        if (!this.contractId) {
          this.$message.error('请先选择基础合同')
          return false
        }
        this.viewSelection = true
      }
      if (type === 'view') {
        this.viewSelection = false
        console.log(this.appForm)
        this.getInvoicesList = this.appForm.invoicesList || []
      }
      this.dialog.invoice.visible = true
    },
    confirmContract (formData) {
      this.contractInfo = formData || {}
      this.contractId = formData.id
      const { contracNo, buyFirmId, buyFirmName, sellFirmId, sellFirmName } = formData
      this.appForm = this._.assign(this.appForm, { contracNo, buyFirmId, buyFirmName, sellFirmId, sellFirmName })
    },
    previewFile (file) {
      this.utils.downloadP('other', file.url)
    },
    confirmInvoices (formData) {
      const data = formData || []
      this.getInvoicesList = data
      const idList = []
      data.forEach(item => {
        idList.push(item.id)
      })
      this.appForm.invoiceIds = idList.join(',')
      this.viewSelection = true
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        let files = []
        if (!this._.isEmpty(data.order.fileList)) {
          const order = data.order.fileList[0].response.data
          order.suffix = 'order'
          order.url = order.path
          delete order.id
          files.push(order)
        }
        if (!this._.isEmpty(data.deliveryBill.fileList)) {
          const deliveryBill = data.deliveryBill.fileList[0].response.data
          deliveryBill.suffix = 'delivery_bill'
          deliveryBill.url = deliveryBill.path
          delete deliveryBill.id
          files.push(deliveryBill)
        }
        if (!this._.isEmpty(data.receivingNote.fileList)) {
          const receivingNote = data.receivingNote.fileList[0].response.data
          receivingNote.suffix = 'receiving_note'
          receivingNote.url = receivingNote.path
          delete receivingNote.id
          files.push(receivingNote)
        }
        if (!this._.isEmpty(data.monthlyStatement.fileList)) {
          const monthlyStatement = data.monthlyStatement.fileList[0].response.data
          monthlyStatement.suffix = 'monthly_statement'
          monthlyStatement.url = monthlyStatement.path
          delete monthlyStatement.id
          files.push(monthlyStatement)
        }
        this.loading.submit = true
        const info = {
          contracNo: data.contracNo,
          buyFirmId: data.buyFirmId,
          buyFirmName: data.buyFirmName,
          sellFirmId: data.sellFirmId,
          sellFirmName: data.sellFirmName,
          paymentTerm: data.paymentTerm,
          amount: data.amount,
          fromType: '01',
          invoiceIds: data.invoiceIds,
          filesList: files
        }
        this.api.assets.receivable.addReceivablePayableInfo(info).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
            this.loading.submit = false
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
